import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {
    // Swiper
    const sliderGallery = document.querySelector('.inicio .slider-inicio');
    if (sliderGallery) {
        new Swiper(sliderGallery, {
            loop: true,
            autoplay: true,
            navigation: {
                prevEl: document.querySelector('.inicio .js-prev'),
                nextEl: document.querySelector('.inicio .js-next'),
            },
        });
    }
});
